import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container"> {/* Apply the class here */}
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date: </strong>25 Sep 2024</p>

      <p>At Nexus Pest Management & Services, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you visit our website, use our services, or interact with us.</p>

      <h2>1. Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> We may collect personal information that you provide to us, such as your name, email address, phone number, and mailing address when you fill out forms or contact us.</li>
        <li><strong>Usage Information:</strong> We may collect information about your interactions with our website, including your IP address, browser type, and pages visited.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We may use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide and maintain our services</li>
        <li>To communicate with you about your inquiries</li>
        <li>To improve our website and services</li>
        <li>To send you marketing communications if you have opted in to receive them</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party vendors to assist us in providing our services.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information as required by law or to protect our rights.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access and update your personal information</li>
        <li>Request deletion of your personal information</li>
        <li>Opt out of marketing communications</li>
      </ul>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website with an updated effective date.</p>

      
    </div>
  );
};

export default PrivacyPolicy;
