import React from 'react';
import './TermsOfService.css'; // Import the CSS file

const TermsOfService = () => {
  return (
    <div className="terms-container"> {/* Apply the class here */}
      <h1>Terms of Service</h1>
      <p><strong>Effective Date: </strong>25 Sep 2024</p>

      <p>Welcome to Nexus Pest Management & Services! These Terms of Service ("Terms") govern your use of our website and services. By accessing or using our services, you agree to be bound by these Terms.</p>

      <h2>1. Services</h2>
      <p>Nexus Pest Management & Services provides pest control and management services. We reserve the right to modify or discontinue our services at any time without notice.</p>

      <h2>2. User Responsibilities</h2>
      <p>You agree to:</p>
      <ul>
        <li>Provide accurate information when requesting our services</li>
        <li>Comply with all applicable laws and regulations</li>
        <li>Not use our services for any unlawful or unauthorized purpose</li>
      </ul>

      <h2>3. Payment and Fees</h2>
      <p>All fees for services rendered will be communicated to you before service initiation. Payment is due upon completion of the service unless otherwise agreed.</p>

      <h2>4. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, Nexus Pest Management & Services shall not be liable for any indirect, incidental, or consequential damages arising from your use of our services.</p>

      <h2>5. Indemnification</h2>
      <p>You agree to indemnify and hold harmless Nexus Pest Management & Services from any claims, losses, liabilities, damages, costs, or expenses arising out of your violation of these Terms.</p>

      <h2>6. Changes to These Terms</h2>
      <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of our services after changes constitute your acceptance of the new Terms.</p>

     
    </div>
  );
};

export default TermsOfService;
