import React from 'react';
import './Contact.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className="contact">
      <h2>Contact Us</h2>
      <div className="contact-info">
        <div className="info-item">
          <FaMapMarkerAlt className="contact-icon" />
          <h3>Our Location</h3>
          <p> Nexus Pest Management & Services<br />
          Plot No.48,Near SBOA School, <br/>
          Navnath Nagar, Jalgaon Road, <br/>
          Darshana Housing,  <br/>
          Chhatrapati Sambhajinagar, Maharashtra,431003<br />
          </p>
        </div>
        <div className="info-item">
          <FaPhone className="contact-icon" />
          <h3>Phone</h3>
          <p>+91 9049581111 / +91 7272831010</p>
        </div>
        <div className="info-item">
          <FaEnvelope className="contact-icon" />
          <h3>Email</h3>
          <p>nexuspms1010@gmail.com</p>
        </div>
      </div>
      <form className="contact-form">
        <input type="text" placeholder="Your Name" required />
        <input type="email" placeholder="Your Email" required />
        <textarea placeholder="Your Message" required></textarea>
        <button type="submit">Send Message</button>
      </form>
      
      {/* Map Container to wrap the iframe */}
      <div className="map-container">
      <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15005.202946160365!2d75.3524678!3d19.9117279!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdbbd6ffa28cb1f%3A0x8bf2bfdee8cbb1b2!2sNexus%20Pest%20Management%20and%20Services!5e0!3m2!1sen!2sin!4v1727251778646!5m2!1sen!2sin" width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="map-iframe"
          />
      </div>
    </div>
  );
};

export default Contact;
