import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa'; // Import icons
import './Navbar.css';
import Logo from '../images/logo.jpg'

const NavigationBar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState('/'); // State for active link

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let navbarClasses = ['navbar-custom'];
  if (scrolled) {
    navbarClasses.push('scrolled');
  }

  return (
    <>
      {/* Top header for Email, Contact, and Social Media Icons */}
      <div className="top-header">
        <Container>
          <Row>
            <Col className="top-info" md={6}>
              <span>
                <FaEnvelope className="icon" />
                <a href="mailto:nexuspms1010@gmail.com">nexuspms1010@gmail.com</a>
              </span>
              <span className="separator">|</span>
              <span>
                <FaPhoneAlt className="icon" />
                <a href="tel:+1234567890">9049581111 / 7272831010</a>
              </span>
            </Col>
            <Col className="text-md-end social-icons" md={6}>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Main Navigation Bar */}
      <Navbar expand="lg" className={navbarClasses.join(' ')} variant="dark">
        <Container>
          <Navbar.Brand as={Link} to="/" className="navbar-title">
          <img src={Logo} alt="Logo" className="logo-image" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {/* Update Nav.Link to set active link on click */}
              <Nav.Link
                as={Link}
                to="/"
                onClick={() => setActiveLink('/')}
                className={activeLink === '/' ? 'active-link' : ''}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => setActiveLink('/about')}
                className={activeLink === '/about' ? 'active-link' : ''}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/services"
                onClick={() => setActiveLink('/services')}
                className={activeLink === '/services' ? 'active-link' : ''}
              >
                Services
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/blogs"
                onClick={() => setActiveLink('/blogs')}
                className={activeLink === '/blogs' ? 'active-link' : ''}
              >
                Blogs
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => setActiveLink('/contact')}
                className={activeLink === '/contact' ? 'active-link' : ''}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
