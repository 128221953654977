import React, { useState } from 'react';
import pestControlImage4 from '../images/rat.webp';
import pestControlImage5 from '../images/spider.webp';
import pestControlImage6 from '../images/termites.webp';
import pestControlImage7 from '../images/Mosq.webp';
import pestControlImage8 from '../images/mice.webp';
import pestControlImage9 from '../images/flies.webp';
import pestControlImage10 from '../images/ant.webp';
import pestControlImage11 from '../images/cock.webp';
import pestControlImage12 from '../images/birds.webp';
import pestControlImage13 from '../images/bed-bugs.webp';  
import pestControlImage14 from '../images/lake.webp';  
import pestControlImage15 from '../images/sustanability.webp';  

import './Blogs.css';

const Blogs = () => {
  const [modalContent, setModalContent] = useState({ title: '', content: '' });
  const [showModal, setShowModal] = useState(false);

  const blogPosts = [
    {
      id: 1,
      title: "Rats Control",
      image: pestControlImage4,
      excerpt: "Learn how to control rats in your living space effectively...",
      content: "Rats are known to cause significant damage to homes and carry diseases. Effective rat control involves identifying entry points and using traps or baits to reduce their presence."
    },
    {
      id: 2,
      title: "Spider Control",
      image: pestControlImage5,
      excerpt: "Learn how to manage spiders around your home...",
      content: "Spiders can help control other insects but may become a nuisance. Regular cleaning and sealing cracks can help prevent spider infestations."
    },
    {
      id: 3,
      title: "Termite Control",
      image: pestControlImage6,
      excerpt: "Protect your property from termites...",
      content: "Termites are destructive pests that feed on wood and can severely damage homes. Regular inspections and the use of professional treatments are necessary for effective termite control."
    },
    {
      id: 4,
      title: "Mosquito Control",
      image: pestControlImage7,
      excerpt: "Learn how to prevent mosquito breeding around your home...",
      content: "Mosquitoes are carriers of diseases like malaria and dengue. Eliminating standing water and using repellents can significantly reduce their population."
    },
    {
      id: 5,
      title: "Mice Control",
      image: pestControlImage8,
      excerpt: "Keep your home free of mice with these tips...",
      content: "Mice can enter homes through small openings and cause damage. Seal entry points and use traps or repellents to control them."
    },
    {
      id: 6,
      title: "Flies Control",
      image: pestControlImage9,
      excerpt: "Prevent flies from invading your home...",
      content: "Flies can contaminate food and spread diseases. Keeping your environment clean and using fly screens can help reduce fly infestations."
    },
    {
      id: 7,
      title: "Ant Control",
      image: pestControlImage10,
      excerpt: "Learn how to control ants effectively...",
      content: "Ants invade homes in search of food. Keeping food sealed and using baits can help control ant infestations."
    },
    {
      id: 8,
      title: "Cockroach Control",
      image: pestControlImage11,
      excerpt: "Manage cockroach infestations with these tips...",
      content: "Cockroaches are resilient pests that can carry diseases. Proper sanitation and the use of insecticides are crucial for cockroach control."
    },
    {
      id: 9,
      title: "Birds",
      image: pestControlImage12,
      excerpt: "Learn how to prevent birds from nesting in unwanted areas...",
      content: "Birds can cause damage to buildings and spread diseases through droppings. Using bird spikes and nets can help prevent bird infestations."
    },
    {
      id: 10,
      title: "Bed Bugs Control",
      image: pestControlImage13,
      excerpt: "Protect your home from bed bugs...",
      content: "Bed bugs are small insects that feed on human blood, often causing itching and allergic reactions. Regular inspection and professional treatment are essential for bed bug control."
    },
    {
        id: 11,
        title: "Lake Management",
        image: pestControlImage14,
        excerpt: "Watershed Approaches...",
        content: `The goals of lake management should be much broader and have discussed qualities of lakes in relation to human utilization in very broad terms, including the impact of invasive organisms, factors associated with fishing quality, aesthetic factors, and even spiritual values.`
      },
      {
        id: 12,
        title: "Sustainability",
        image: pestControlImage15,
        excerpt: "environmental, economic, and social....",
        content: `Sustainability is a social goal for people to co-exist on Earth over a long period of time. Definitions of this term are disputed and have varied with literature, context, and time.[`
      },
  ];

  const openModal = (post) => {
    setModalContent(post);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="blogs">
      <h2>Our Blogs</h2>
      <div className="blog-container">
        {blogPosts.map((post) => (
          <div className="blog-post" key={post.id}>
            <h3>{post.title}</h3>
            <img
              src={post.image}
              alt={post.title}
              className="blog-image"
              style={{ width: '300px', height: '200px' }}
            />
            <p>{post.excerpt}</p>
            <button className="read-more" onClick={() => openModal(post)}>
              Read More
            </button>
          </div>
        ))}
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">{modalContent.title}</h5>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>{modalContent.content}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
