import React, { useState } from 'react';
import './EnquiryForm.css'; // Import your CSS for styling

const EnquiryForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can add logic to send the form data to your backend or an API
    console.log('Form submitted:', formData);
    onClose(); // Close the form after submission
  };

  return (
    <div className="enquiry-form-overlay">
      <div className="enquiry-form-container">
        <h2>Enquiry Form</h2>
        <form onSubmit={handleSubmit}>
  <div className="form-group">
    <label htmlFor="name">Name:</label>
    <input
      type="text"
      id="name"
      name="name"
      value={formData.name}
      onChange={handleChange}
      required
    />
  </div>
  <div className="form-group">
    <label htmlFor="email">Email:</label>
    <input
      type="email"
      id="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
      required
    />
  </div>
  <div className="form-group">
    <label htmlFor="message">Message:</label>
    <textarea
      id="message"
      name="message"
      value={formData.message}
      onChange={handleChange}
      required
    ></textarea>
  </div>
  
  {/* Buttons with proper alignment */}
  <div className="form-buttons">
    <button type="submit">Submit</button>
    <button type="button" onClick={onClose}>Cancel</button>
  </div>
</form>

      </div>
    </div>
  );
};

export default EnquiryForm;
