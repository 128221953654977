import React from 'react';
import './About.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h2>About Nexus Pest Management and Services</h2>

      <h3>Who We Are</h3>
      <p>
        Nexus Pest Management and Services is a leading provider of pest control solutions dedicated to protecting your home and business from unwanted pests. With years of experience in the industry, our team of skilled professionals employs cutting-edge techniques and eco-friendly products to ensure a pest-free environment.
      </p>

      <h3>Our Mission</h3>
      <p>
        At Nexus Pest Management, our mission is to deliver effective pest control services that safeguard your property while promoting a healthier, pest-free living and working environment. We strive to provide peace of mind to our clients through proactive prevention, thorough inspections, and timely interventions.
      </p>

      <h3>Our Values</h3>
      <ul>
        <li><strong>Integrity</strong>: We uphold the highest standards of honesty and transparency in our services, building trust with our clients.</li>
        <li><strong>Excellence</strong>: Our commitment to quality drives us to continuously improve our techniques and customer service.</li>
        <li><strong>Customer-Centric Approach</strong>: We prioritize our customers’ needs, offering tailored solutions and responsive support.</li>
        <li><strong>Sustainability</strong>: We are dedicated to using environmentally friendly products and practices that protect both your property and the planet.</li>
      </ul>

      <h3>Our Services</h3>
      <ul>
        <li>Residential Pest Control</li>
        <li>Commercial Pest Control</li>
        <li>Termite Inspections and Treatments</li>
        <li>Integrated Pest Management (IPM)</li>
        <li>Wildlife Control</li>
        <li>Preventive Services</li>
      </ul>

      <h3>Why Choose Us?</h3>
      <ul>
        <li><strong>Expert Team</strong>: Our licensed and trained technicians possess extensive knowledge of pest behavior and treatment options.</li>
        <li><strong>Customized Solutions</strong>: We assess each situation individually to provide the most effective and economical solutions.</li>
        <li><strong>Advanced Techniques</strong>: We use the latest technology and methods to ensure safe and efficient pest management.</li>
        <li><strong>Satisfaction Guarantee</strong>: Your satisfaction is our priority. We work diligently to ensure you are happy with our services.</li>
      </ul>

      <h3>Community Commitment</h3>
      <p>
        Nexus Pest Management is proud to serve our local community. We believe in giving back through various initiatives and partnerships that promote health, safety, and environmental stewardship.
      </p>

      <h3>Contact Us</h3>
      <p>
        Experience the Nexus Pest Management difference. Let us help you reclaim your space from pests! Contact us today for a free consultation or to schedule a service.
      </p>
    </div>
  );
};

export default AboutUs;
