import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import pestControlImage1 from '../images/pestControl1.jpg';
import pestControlImage2 from '../images/pestControl2.webp';
import pestControlImage3 from '../images/pestControl3.webp';
import pestControlImage4 from '../images/rat.webp';
import pestControlImage5 from '../images/spider.webp';
import pestControlImage6 from '../images/termites.webp';
import pestControlImage7 from '../images/Mosq.webp';
import pestControlImage8 from '../images/mice.webp';
import pestControlImage9 from '../images/flies.webp';
import pestControlImage10 from '../images/ant.webp';
import pestControlImage11 from '../images/cock.webp';
import pestControlImage12 from '../images/birds.webp';
import pestControlImage13 from '../images/bed-bugs.webp';

import './Home.css';

const Home = () => {
    const [showAllImages, setShowAllImages] = useState(false);

  const pestImages = [
    { src: pestControlImage4, name: 'Rat' },
    { src: pestControlImage5, name: 'Spider' },
    { src: pestControlImage6, name: 'Termites' },
    { src: pestControlImage7, name: 'Mosquitoes' },
    { src: pestControlImage8, name: 'Mice' },
    { src: pestControlImage9, name: 'Flies' },
    { src: pestControlImage10, name: 'Ant' },
    { src: pestControlImage11, name: 'Cockroaches' },
    { src: pestControlImage12, name: 'Birds' },
    { src: pestControlImage13, name: 'Bed Bugs' }
  ];

  const displayedImages = showAllImages ? pestImages : pestImages.slice(0, 5);

  return (
    <div>
      {/* Carousel Section */}
      <div className="carousel-container">
        <div className="carousel-content">
          <div className="carousel-text">
            <Carousel interval={3000} pause="hover">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={pestControlImage1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={pestControlImage2}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={pestControlImage3}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="carousel-text-content">
            <h2>Welcome to Our Pest Control Services</h2>
            <p>We provide top-notch pest control services tailored to your needs.
              Our team is dedicated to ensuring your home and business are pest-free with safe and effective solutions.
              Contact us today to get a free consultation and protect your property from unwanted pests.</p>
            <h2>Vision Management</h2>
            <p>
              Create a vision management plan for a pest control service with a clear vision to lead in eco-friendly solutions. Set measurable goals for market leadership, customer satisfaction, sustainability, and employee development. Implement strategies like digital marketing, service diversification, customer engagement, and technology integration, ensuring continuous monitoring and improvement.
            </p>
            <button className="learn-more-btn">Learn More</button>

          </div>
        </div>
      </div>

      {/* Pest Images Section */}
      <div className="pest-images-section">
        <h2>Pest Gallery</h2>
        <div className="pest-images-container">
          {displayedImages.map((image, index) => (
            <div key={index} className="pest-image-item">
              <img src={image.src} alt={`Pest ${index + 1}`} className="pest-image" />
              <h3 style={{ color: 'black', marginTop: '20px' }}>{image.name}</h3>
            </div>
          ))}
        </div>
        <button className="toggle-images-btn" onClick={() => setShowAllImages(!showAllImages)}>
          {showAllImages ? 'Show Less' : '+ Show More'}
        </button>
      </div>

      {/* Address Section */}
      <div className="address-section">

        <div className="address-map-container">
          <address className="address-content">
            <h2>Our Address</h2>
            Nexus Pest Management & Services<br />
            Plot No.48, Near SBOA School,<br />
            Navnath Nagar, Jalgaon Road,<br />
            Darshana Housing,<br />
            Chhatrapati Sambhajinagar, Maharashtra, 431003<br />
          </address>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15005.202946160365!2d75.3524678!3d19.9117279!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdbbd6ffa28cb1f%3A0x8bf2bfdee8cbb1b2!2sNexus%20Pest%20Management%20and%20Services!5e0!3m2!1sen!2sin!4v1727251778646!5m2!1sen!2sin" width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="map-iframe"
          />
        </div>
      </div>
      
    </div>
  );
};

export default Home;
