import React, { useState } from 'react';
import './Services.css';
import Background from '../images/background.webp';
import Residential from '../images/residentials.webp';
import Commercial from '../images/commercial.webp';
import Termit from '../images/termits.webp';
import Rodent from '../images/rodent.webp';
import BedBug from '../images/bedbug.webp';
import Food from '../images/food.webp';
import Rest from '../images/restaurant.webp';
import Supermarket from '../images/supermarket.webp';
import Retail from '../images/retail.webp';
import IT from '../images/IT.webp';
import Manufacturing from '../images/manufacturing.webp';
import Facility from '../images/facility.webp';
import Hospitals from '../images/hospitals.webp';
import Pharmaceuticals from '../images/farmacitical.webp';
import School from '../images/school.webp';
import Construction from '../images/construction.webp';
import Hospitality from '../images/hospitality.webp';
import Industrial from '../images/industrial.webp'
// Service data with images
const servicesData = [
  {
    title: "Residential Pest Control",
    description: "We provide effective pest control solutions for homes, ensuring a safe and healthy living environment by eliminating common pests.",
    image: Residential // Corrected
  },
  {
    title: "Commercial Pest Control",
    description: "Our commercial pest control services cater to businesses, protecting your premises from pests and ensuring compliance with health and safety regulations.",
    image: Commercial // Corrected
  },
  {
    title: "Industrial Pest Control",
    description: "Our industrial pest control services cater to businesses, protecting your premises from pests and ensuring compliance with health and safety regulations.",
    image: Industrial // Corrected
  },
  
];

// Business Services Data
const businessServicesData = [
  {
    title: "Food Processing Plants",
    shortDescription: "Pest control solutions for food safety in processing.",
    details: "We provide tailored pest control services to ensure food safety in food processing plants. Our team uses advanced techniques to prevent pest infestations and comply with industry regulations.",
    image: Food // Corrected
  },
  {
    title: "Restaurants",
    shortDescription: "Maintaining hygiene and safety standards in restaurants.",
    details: "Our pest control services for restaurants ensure compliance with hygiene and safety standards. We provide regular inspections and treatments to prevent infestations and protect your business.",
    image: Rest // Corrected
  },
  {
    title: "Hypermarkets and Supermarkets",
    shortDescription: "Protecting large retail spaces from pest issues.",
    details: "We offer comprehensive pest management solutions for hypermarkets and supermarkets. Our strategies are designed to protect your inventory and maintain a pest-free shopping environment for customers.",
    image: Supermarket // Corrected
  },
  {
    title: "Food Retail",
    shortDescription: "Safe pest management for food retail operations.",
    details: "Our food retail pest control services help maintain safety and compliance. We provide thorough inspections and tailored treatments to safeguard your food products and facilities.",
    image: Retail // Corrected
  },
  {
    title: "IT",
    shortDescription: "Pest control solutions for tech facilities.",
    details: "Our pest control services for IT facilities ensure a pest-free environment for sensitive equipment and personnel. We focus on non-invasive methods to maintain safety and productivity.",
    image: IT // Corrected
  },
  {
    title: "Manufacturing Plant",
    shortDescription: "Pest prevention strategies for manufacturing operations.",
    details: "We provide pest control services tailored to manufacturing plants, preventing infestations that could disrupt operations and affect product quality. Our solutions are effective and compliant with regulations.",
    image: Manufacturing // Corrected
  },
  {
    title: "Facility Management",
    shortDescription: "Integrated pest management for facility managers.",
    details: "Our integrated pest management solutions are designed for facility managers, providing ongoing support and monitoring to maintain pest-free environments in all types of facilities.",
    image: Facility // Corrected
  },
  {
    title: "Pharmaceuticals",
    shortDescription: "Ensuring safety and compliance in pharmaceutical settings.",
    details: "We offer specialized pest control services for pharmaceutical companies, ensuring compliance with strict safety regulations and protecting products from pest-related contamination.",
    image: Pharmaceuticals // Corrected
  },
  {
    title: "Hospitals and Clinics",
    shortDescription: "Critical pest control for healthcare facilities.",
    details: "Our pest control services for hospitals and clinics are essential for maintaining sterile environments. We provide discreet, effective solutions that prioritize patient safety.",
    image: Hospitals // Corrected
  },
  {
    title: "Schools and Private Institutions",
    shortDescription: "Maintaining pest-free learning environments.",
    details: "We ensure that schools and private institutions are pest-free. Our services focus on creating safe and healthy environments for students and staff, preventing infestations and ensuring comfort.",
    image: School // Corrected
  },
  {
    title: "Building and Construction",
    shortDescription: "Pest management during and after construction projects.",
    details: "Our pest control services support building and construction projects by managing pests during construction and preventing future infestations in completed structures.",
    image: Construction // Corrected
  },
  {
    title: "Hospitality",
    shortDescription: "Essential pest control for hotels and resorts.",
    details: "We provide comprehensive pest control solutions for the hospitality industry, ensuring a pleasant experience for guests and protecting the reputation of hotels and resorts.",
    image: Hospitality // Corrected
  },
];

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  const handleReadMore = (service) => {
    setSelectedService(service);
  };

  const handleClose = () => {
    setSelectedService(null);
  };

  return (
    <div className="services">
      {/* Background section */}
      <div className="background-section" style={{ backgroundImage: `url(${Background})` }}>
        <h1>Professional Pest Control Services</h1>
        <p>Ensuring a pest-free environment for homes and businesses with effective solutions.</p>
      </div>

      <h2>Our Services</h2>
      <ul className="services-list">
        {servicesData.map((service, index) => (
          <li key={index} className="service-item">
            <img src={service.image} alt={service.title} className="service-image" />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </li>
        ))}
      </ul>

      <h2>Business Services</h2>
      <ul className="business-services-list">
        {businessServicesData.map((service, index) => (
          <li key={index} className="business-service-item">
            <img src={service.image} alt={service.title} className="business-service-image" />
            <h3>{service.title}</h3>
            <p>{service.shortDescription}</p>
            <button onClick={() => handleReadMore(service)}>Read more</button>
          </li>
        ))}
      </ul>

      {selectedService && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleClose}>&times;</span>
            <h3>{selectedService.title}</h3>
            <img src={selectedService.image} alt={selectedService.title} className="modal-service-image" />
            <p>{selectedService.details}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
