import React, { useState } from 'react';
import EnquiryForm from './EnquiryForm'; // Import the enquiry form
import './FloatingButtons.css'; // Import your CSS for styling

const FloatingButtons = () => {
  const [isEnquiryOpen, setEnquiryOpen] = useState(false);

  const handleEnquiryToggle = () => {
    setEnquiryOpen(!isEnquiryOpen);
  };

  const handleClose = () => {
    setEnquiryOpen(false);
  };

  const handleCallNow = () => {
    window.open('tel:+919049581111'); // Replace with your phone number
  };

  const handleWhatsApp = () => {
    window.open('https://wa.me/9049581111'); // Replace with your WhatsApp number
  };

  return (
    <>
      {/* WhatsApp button on the left */}
      <div className="whatsapp-floating-button" onClick={handleWhatsApp}>
        <i className="fab fa-whatsapp"></i>
      </div>

      {/* Enquiry and Call buttons on the right */}
      <div className="floating-buttons-right">
        <button className="enquire-button" onClick={handleEnquiryToggle}>
          <i className="fas fa-envelope"></i>
        </button>
        <button className="call-button" onClick={handleCallNow}>
          <i className="fas fa-phone"></i>
        </button>
      </div>

      {/* Enquiry Form modal */}
      {isEnquiryOpen && <EnquiryForm onClose={handleClose} />}
    </>
  );
};

export default FloatingButtons;
